import ApiService from "@/core/services/ApiService";
import { AccountItem } from "@/core/types/application/AccountSettings";
import { FormData } from "@/core/types/gws-ap2tki/lsp/FormData";
import { ListItem } from "@/core/types/gws-ap2tki/lsp/ListItem";
import { LspWrapper } from "@/core/types/gws-master/master/lsp/LspWrapper";
import { ApiResponse, ErrorItem } from "@/core/types/misc/ApiResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { defineStore } from "pinia";

export const useAccountSettingsStore = defineStore({
  id: "accountSettings",
  state: () => {
    return {
      stateAccountPayload: {
        name: "",
        email: "",
        phone: "",
        avatar: "",
      } as AccountItem,
      statePasswordPayload: {
        old_password: "",
        new_password: "",
        conf_password: "",
      },
      stateInfoLspPayload: {
        name: '',
        vocational: [] as string[],
        destination_country_id: '',
        licensing_file: '',
        cv_file: '',
        licensing_number: '',
        sk_number: '',
        lsp_start_date: '',
        lsp_end_date: '',
        province_id: '',
        city_id: '',
        address: '',
      },
      stateSipPayload: {
        id: "",
        sip_number: "",
        sip_start_date: "",
        sip_end_date: "",
        sip_file: "",
        created_at: "",
        updated_at: "",
        deleted_at: null,
        rejected_at: null,
        approved_at: null,
        company_id: "",
        reject: true,
        approve: true,
      },

      stateError: false as boolean,
      stateErrorMsg: "",
      stateErrors: [] as ErrorItem[],
      stateLoading: false,
    };
  },
  getters: {
    error: (state) => {
      return state.stateError;
    },
    errorMsg: (state) => {
      return state.stateErrorMsg;
    },
    loading: (state) => {
      return state.stateLoading;
    },
    accountPayload: (state) => {
      return state.stateAccountPayload;
    },
    lspPayload: (state) => {
      return state.stateInfoLspPayload;
    },
    passwordPayload: (state) => {
      return state.statePasswordPayload;
    },
    sipPayload: (state) => {
      return state.stateSipPayload;
    },
  },
  actions: {
    async getUserDetail(id: string) {
      try {
        const response: AxiosResponse<ApiResponse<AccountItem>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/users/${id}`
          );
        this.stateAccountPayload = response.data.data;
      } catch (error: any) {
        console.log(error);
      }
    },

    async getLspDetail(id: string) {
      try {
        const response: AxiosResponse<ApiResponse<LspWrapper<ListItem>>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/lsp/${id}`
          );
        const lspDetail = response.data.data.lsp;
        this.stateInfoLspPayload = {
          name: lspDetail.name,
          vocational: lspDetail.vocational,
          destination_country_id: lspDetail.destination_country.id,
          licensing_file: lspDetail.licensing_file,
          cv_file: lspDetail.cv_file,
          licensing_number: lspDetail.licensing_number,
          sk_number: lspDetail.sk_number,
          lsp_start_date: lspDetail.lsp_start_date,
          lsp_end_date: lspDetail.lsp_end_date,
          province_id: lspDetail.province.id,
          city_id: lspDetail.city.id,
          address: lspDetail.address,
        };
      } catch (error: any) {
        console.log(error);
      }
    },
    async getSipDetail(id: string) {
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/companies/${id}/sip`
        );
        this.stateSipPayload = response.data.data.sip;
      } catch (error: any) {
        console.log(error);
      }
    },

    async submitUpdateAccount(id: string) {
      const payload = this.stateAccountPayload;
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/users/${id}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          console.log(response.data.data);
        } else {
          this.stateError = true;
          this.stateErrorMsg = response.data.meta.message;
        }
      } catch (error: any) {
        this.stateError = true;
        this.stateErrors.push(error);
        console.log(error);
      }
    },


    async submitUpdateLsp(id: string) {
      const payload = this.stateInfoLspPayload;
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/lsp/${id}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          console.log(response.data.data);
        } else {
          this.stateError = true;
          this.stateErrorMsg = response.data.meta.message;
        }
      } catch (error: any) {
        this.stateError = true;
        this.stateErrors.push(error);
        console.log(error);
      }
    },

    async submitUpdateSip(id: string) {
      const payload = this.stateSipPayload;
      try {
        if (this.stateSipPayload.id) {
          const response: AxiosResponse<ApiResponse<any>> =
            await ApiService.patch(
              `${process.env.VUE_APP_GWS_API_BASE_URL}/sip/${this.stateSipPayload.id}`,
              payload as AxiosRequestConfig
            );

          if (response.data.meta.code === 200) {
            console.log(response.data.data);
          } else {
            this.stateError = true;
            this.stateErrorMsg = response.data.meta.message;
          }
        } else {
          const response: AxiosResponse<ApiResponse<any>> =
            await ApiService.post(
              `${process.env.VUE_APP_GWS_API_BASE_URL}/sip`,
              payload as AxiosRequestConfig
            );

          if (response.data.meta.code === 200) {
            console.log(response.data.data);
          } else {
            this.stateError = true;
            this.stateErrorMsg = response.data.meta.message;
          }
        }
      } catch (error: any) {
        this.stateError = true;
        this.stateErrors.push(error);
        console.log(error);
      }
    },
    async submitUpdatePassword(id: string) {
      const payload = {
        password: this.statePasswordPayload.new_password,
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/users/${id}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          console.log(response.data.data);
        } else {
          this.stateError = true;
          this.stateErrorMsg = response.data.meta.message;
        }
      } catch (error: any) {
        this.stateError = true;
        this.stateErrors.push(error);
        console.log(error);
      }
    },
  },
});
